@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';

.Alert {
  margin-top: 1rem;

  .inner {
    display: grid;
    grid-template-columns: 21fr 1.5rem;
  }

  .HelpLink {
    text-decoration: underline;
    color: $pizazz;
    display: inline-flex;
    align-items: center;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .Close {
    color: $tundora;
  }
}
