@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';
@import '../../base/variables';

.CreateAccountFormModal {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .Card {
    width: 80%;

    @include breakpoint(sm) {
      padding: 2rem;
      width: 24rem;
    }
  }
}