@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';

@mixin bigButton {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 4s cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
  color: $white;

  &:focus,
  &:active {
    color: $pizazz;
    opacity: .75;
    outline: none;
  }

  svg {
    width: 5rem;
    height: 5rem;

    @include breakpoint(sm) {
      width: 10rem;
      height: 10rem;
    }
  }
}

@mixin video {
  // max-height: 56.25vw;
  min-height: 16rem;
  overflow: hidden;
  img {
    max-width: 100%;
  }
}

@mixin videoContainer {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
  border: 1px solid transparent;


  &:focus,
  &:active {
    border-color: $crimson;
  }

  .Title {
    display: block;
    width: 100%;
    
    h2 {
      margin-bottom: .5rem;
      text-align: left;
      width: 100%;
    }
  }

  .TitleFullscreen {
    display: none;
  }

  .Video {
    @include video;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  
    video {
      max-width: 100%;

      &::-webkit-media-controls,
      &::-webkit-media-controls-enclosure {
        display:none !important;
      }

      &[poster] {
        object-fit: fill;
      }
    }
  }

  .Processing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .5rem;
  }

  .BigButton {
    @include bigButton;
    opacity: 0;
  }

  .BigButtonShow {
    @include bigButton;
    opacity: .75;
  }

  .Poster {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
  }  
}

.VideoContainer {
  @include videoContainer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.VideoContainerFullscreen {
  @include videoContainer;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;

  .VideoFullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    video {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .Poster {
    display: none;
  }
}

.portrait {
  max-width: 100%;
}

.landscape {
  max-width: 100%;
}
