@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';

@mixin fave {
  @include like;

  svg {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.Favourite {
  @include fave;
  color: $white;
}

.Favourited {
  @include fave;
  color: $pizazz;
}