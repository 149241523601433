@import '../../base/colors';

.Error {
  color: $red;
  display: flex;
  align-items: center;
  justify-self: center;
  text-align: center;
  padding: 1rem;
  gap: .25rem;
  background: rgba($white, .9);
  border-radius: .5rem;
  margin-bottom: 1rem;
}