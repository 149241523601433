@import '../../../base/breakpoints';
@import '../../../base/colors';
@import '../../../base/mixins';

.NotificationsMenuItem {
  position: relative;

  .Icon {
    position: relative;
  }

  .Text {
    @include navText;
    display: none;
  }

  .Badge {
    position: absolute;
    top: 0rem;
    right: 0rem;
    background-color: $pizazz;
    border: 1px solid $pizazz;
    border-radius: 100%;
    width: 1rem;
    height: 1rem;
    font-size: .6rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  
    @include breakpoint(md) {
      top: -0.25rem;
      right: -0.25rem;
    }
  };

  &:hover,
  &[aria-current="page"] {
    .Badge {
      background: $white;
    }
  }
}


