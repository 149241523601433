@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';

.ImagePicker {
  label {
    color: $cerulean;
    cursor: pointer;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  input[type="file"] {
    display: none;
  }
  
  .UploadButton {
    span { 
      margin-left: .5rem;
    }
  }
}
