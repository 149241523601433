@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';

.ReplyingTo {
  @include postReplyLeftPad;
  color: $dove-gray;
  font-size: .8rem;
  padding: .5rem 1rem;
}
