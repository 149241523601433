@import '../../base/colors';

.Close {
  position: absolute;
  top: .5rem;
  right: .5rem;
  color: $mine-shaft;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}