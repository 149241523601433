@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/mixins';

.UserField {
  @include UserField;

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.UserFieldMultiline {
  @include UserField;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;

  @include breakpoint(sm) {
    -webkit-line-clamp: 1; /* number of lines to show */
    max-width: 40rem;
  }
}

.UserFieldOpen {
  @include UserField;
  display: block;
}

.userName {
  font-size: .9rem;
  grid-column: 2;
  grid-row: 3;
  overflow: visible;
}

.url,
.twitter {
  display: flex;
}
