@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';

.SubscribeContainer {
  margin-bottom: 1rem;
}

.NoOffers,
.SubscribeButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  text-decoration: none;
  margin-top: 1rem;
  gap: 1rem;
  text-align: center;

  .Card {
    position: relative;
    padding: 2rem;
    background: $white;
    color: $mine-shaft;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem

    p {
      margin: 0 0 1rem 0;
    }
  }

  .Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
  }
}

.Close {
  position: absolute;
  top: .5rem;
  right: .5rem;
  color: $mine-shaft;
}

.Confirm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}