@import '../../base/breakpoints';
@import '../../base/colors';

.ConfirmEmailNag {
  background: #ed6c02;
  
  @include breakpoint(sm) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > * {
    justify-content: center;
  }

  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1.5rem;
  }
}