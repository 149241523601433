@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';

.HasImage {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-rows: 2rem 1fr 2rem;
  width: 100%;
  height: 100%;
  z-index: 20;

  .RemoveImage {
    color: $cerulean;
    grid-row: 1;
    grid-column: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .EditImage {
    color: $cerulean;
    grid-row: 3;
    grid-column: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.ModalInner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  @include breakpoint(sm) {
    max-width: 50vw;
  }
}

.Buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;

  a {
    text-decoration: none;
  }
}


.CropRatios {
  @include button;
  display: flex;
  align-items: center;
  gap: 1rem;

  .Portrait {
    border: 1px solid $black;
    width: .6rem;
    height: 1rem;
  }

  .Square {
    border: 1px solid $black;
    width: 1rem;
    height: 1rem;
  }

  .Landscape {
    border: 1px solid $black;
    width: 1.4rem;
    height: 1rem;
  }
}