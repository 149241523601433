@import '../../base/breakpoints';
@import '../../base/colors';

.Footer {
  @include breakpoint(sm) {
    display: flex;
    grid-template-columns: 1fr;
    grid-template-rows: 1em 1em;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: .5rem;
    padding: 1rem;
    font-size: .8rem;
    color: $dove-gray;
    grid-column: 3;
    position: fixed;
    bottom: 0;
  }
}
