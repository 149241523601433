@mixin nuttify {
  font-family: 'Urbanist', sans-serif;
  font-style: italic;
}

@mixin lato {
  font-family: 'Lato', sans-serif;
}

@mixin h1 {
  @include nuttify;
  font-size: 1.5rem;
  color: $pizazz;
}