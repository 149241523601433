@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/variables';

.CarouselImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.CarouselImage {
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    max-width: unset;
  }
}

.BackgroundImage {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  filter: blur(5px);
  position: absolute;
  z-index: 0;
  opacity: .5;
}
