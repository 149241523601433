@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';

.Share {
  grid-column: 5;
  grid-row: 2;
}

.AlertInner {
  min-width: 12.5rem;
  
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0 0 .2rem 0;
      margin: 0;
      display: flex;

      &:last-child {
        padding-bottom: 0;
      }

      a {
        background-color: $pizazz;
        padding: .5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: .5rem;
        border-radius: .25rem;
        width: 100%;
    
        &:hover {
          background-color: $white;
        }
      }
    }
  }
}

.EmailInput {
  padding: .5rem;

  .InputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}