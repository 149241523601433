@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/mixins';

.UserVerified {
  line-height: .5rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
}