@import '../../../base/breakpoints';
@import '../../../base/colors';
@import '../../../base/typography';

@mixin videoControls {
  @include lato;
  background: rgba(0, 0, 0, .75);
  color: $white;
  display: grid;
  grid-template-columns: 1rem 3rem 1fr 3rem 1rem 1rem;
  gap: .5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;

  a {
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus,
    &:hover,
    &:active {
      outline: none;
      color: $pizazz;
    }
  }
}

@mixin videoControlsFullscreen {
  @include videoControls;
  background: rgba(0, 0, 0, .75);
  position: absolute;
  width: calc(90% - 1rem);
  left: 5%;
  bottom: 1rem;
  padding: .5rem;
  opacity: 1;
  transition: opacity 4s cubic-bezier(0.19, 1, 0.22, 1);

  .portrait & {
    @include breakpoint(sm) {
      max-width: 50%;
      left: 25%;
    }
  }
}

.VideoControls {
  @include videoControls;
}

.VideoControlsFullscreen {
  @include videoControlsFullscreen;
}

.VideoControlsFullscreenHidden {
  @include videoControlsFullscreen;
  opacity: 0
}

.portrait {
  max-width: 100%;
}

.landscape {
  max-width: 100%;
}