@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';

.GetVerifiedButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;

  a {
    text-decoration: none;

    button {
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: .5rem;
    }
  }
}
