@import './breakpoints';
@import './colors';
@import './typography';
@import './mixins';
@import './variables';

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&family=Urbanist:ital,wght@1,600&display=swap');

html {
  height: 100%;
  overflow:auto;
}

body {
  @include lato;
  color: $white;
  background: $cod-gray;
  min-height: 100%;
}

* {
  box-sizing: border-box;

  &:focus,
  &:active {
    outline: none;
  }
}

::selection {
  background: $pizazz;
}
::-moz-selection {
  background: $pizazz;
}

a {
  color: $white;
  text-decoration: none;

  &:hover {
    color: $pizazz;
  }
}

h1 {
  font-size: 2rem;
  padding: 0 1rem;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
}

li {
  line-height: 1.5rem;
}

p {
  margin: 0 0 1rem 0;
  line-height: 1.5rem;
}

dl {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 10rem 1fr;
  gap: 0;

  dt,
  dd {
    grid-column: 1;
    font-weight: 700;
    border-bottom: 1px solid rgba($white, .25);
    padding: .25rem;
    margin: 0;
    min-height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  dd {
    grid-column: 2;
  }
}

img {
  max-width: 100%;
}

header {
  nav {
    ul {
      padding: 0;
      border: 0;
      list-style: none;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: .5rem 0;

      @include breakpoint(sm) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: .5rem;
        width: 2rem;
        margin: 0;
      }

      @include breakpoint(md) {
        width: 11rem;
      }

      li {
        a {
          color: $white;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
      
          @include breakpoint(sm) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: .25rem;
            text-align: left;
          }
      
          &:hover,
          &:active,
          &:focus {
            outline: none;
            color: $pizazz;
          }
        }
      }
    }
  }
}

.button {
  @include button;
}

/* Change the white to any color */
input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus, 
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &:-internal-autofill-selected {
    background-color: rgba($white, .25) !important;
    color: $mine-shaft !important;
  }
}

figure {
  margin: 1rem 0;  
}

figcaption {
  padding: 0.25rem 1rem .5rem;
  background-color: rgba($white, .1);
}
