@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';

.PaymentAddMethod { 
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  h2,
  p {
    margin: 0
  }

  a {
    text-decoration: none;
  }
}

.StripeContainer {
  margin: 1rem 0;
}