@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';
@import '../../base/variables';

.PremiumPostTeaser {
  position: relative;
  height: 15rem;
  width: 100%;

  .Background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    filter: blur(15px);
    opacity: .5;
  }

  .Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .Lock {
      svg {
        width: 150px;
        height: 150px;
      }
    }
  }
}
