@import '../../../base/breakpoints';
@import '../../../base/colors';
@import '../../../base/mixins';

.FooterNavContainer {
  display: flex;
  flex-direction: column;
}


.FooterNav {
  list-style-type: none;
  padding: .5rem 0;
  margin: 0;
  position: relative;

  @include breakpoint(sm) {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    padding: 0;
    font-size: .8rem;
    color: $dove-gray;
    grid-column: 3;
    grid-row: 1;
    align-items: center;
    justify-content: flex-start;
  }

  li a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 1rem;
    color: $dove-gray;
    
    &:hover {
      color: $white;
    }

    svg {
      color: $dove-gray;

      &:hover {
        color: $white;
      }
    }
  }
}

.hidden {
  @include visuallyHidden;
}
