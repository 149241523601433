@import '../../base/breakpoints';
@import '../../base/colors';

.Copyright {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  color: $silver-chalice;
  font-size: .8rem;
  padding: 1rem;
}
