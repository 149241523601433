$gallery: #efefef;
$silver-chalice: #9e9e9e;
$red: red;
$green: #008000;
$white: #fff;
$mine-shaft: #333;
$bright-turquoise: #0fbce6;
$catskill-white: #ECF4F6;
$cerulean: #039be5;
$crimson: #da0e1c;
$alto: #ddd;
$wildsand: #f5f5f5;
$tundora: #444;
$silver: #c3c3c3;
$gray: #888;
$button-disabled: #dfdfdf;
$black: #000;
$dune: #3b3835;
$crimson-hover: #ffa835;
$seashell: #f1f1f1;
$teal: #072b31;
$dove-gray: #696969;
$cod-gray: #141414;
$sunshade: #ffa835;
$pizazz: #9A1F33;
$form-bg: #222;
