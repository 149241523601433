@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/variables';

.MessageButton {
  grid-column: 4;
  grid-row: 2;
  cursor: pointer;
};

.MessageButtonLoading {
  grid-column: 4;
  grid-row: 2;
  color: $silver-chalice;

  img {
    filter: grayscale(100%);
  }
}