@import '../../../base/breakpoints';
@import '../../../base/colors';
@import '../../../base/typography';

.ToggleNav {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  padding: .5rem;
  align-items: center;
  justify-content: center;
  z-index: 150;
  background-color: rgba($cod-gray, .5);

  > svg {
    width: 1.75rem;
    height: 1.75rem;
    filter: drop-shadow( 2px 2px 2px rgba(255, 255, 255, .5));
  }
}


.Close {
  position: absolute;
  top: 1rem;
  right: 0;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: .5rem;
  padding: 1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.MobileNav {
  width: 75vw;
  padding: 1rem;

  @include breakpoint(sm) {
    width: 15rem;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    text-decoration: none;
    line-height: 2rem;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 80%;

    li {
      margin: 0;
      padding: 0;

      ul {
        margin: 0;
        padding: 0;

        li {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

