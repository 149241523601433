@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/variables';

.PremiumContentCheckbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > span {
    padding: 0 .25rem;
  }
}

.Alert {
  bottom: 0;
  grid-row: 2;
  grid-column: 1 / 5;

  a {
    color: $pizazz;
  }
}