@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';


.Close {
  position: absolute;
  top: .75rem;
  right: .5rem;
}

.SearchModal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.SearchModalInner {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 1rem 3rem 2rem 2rem;
  background: $cod-gray;
}