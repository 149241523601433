@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';

.HasImage {
  display: grid;
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-rows: 2rem 1fr 2rem;
  width: 100%;
  height: 100%;

  .RemoveImage {
    color: $cerulean;
    grid-row: 1;
    grid-column: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .EditImage {
    color: $cerulean;
    grid-row: 3;
    grid-column: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ProfileImageEditor {
  label {
    color: $cerulean;
    cursor: pointer;
    padding: 1rem;
    z-index: 10;
  }

  input[type="file"] {
    display: none;
  }
}


.Modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalInner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include breakpoint(sm) {
    max-width: 80vw;
    max-height: 80vh;
  }
}

.Cropper {
  width: 100%;
  height: 70%;

  @include breakpoint(sm) {
    max-height: 70%;
    overflow: hidden;

    > * {
      max-height: 70%;
    }
  }
}

.Buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
  background-color: rgba($cod-gray, .9);

  a {
    text-decoration: none;
  }
}


