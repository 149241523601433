@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';

@mixin profileHeaderMinimal {
  @include lato;
  color: $white;
  display: grid;
  gap: .5rem;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  text-decoration: none;
}

.ProfileHeaderMinimal,
.ProfileHeaderMinimalSingle {
  @include profileHeaderMinimal;
  grid-template-columns: 4rem 1fr 1fr;
  padding: 0 1rem;
}

.ProfileHeaderMinimalComment {
  @include profileHeaderMinimal;
  grid-template-columns: 3rem 1fr 10rem;
  padding-left: 1rem;
}

.ProfileHeaderMinimalSubscribe {
  @include profileHeaderMinimal;
  grid-template-columns: 3rem 1fr 10rem;
  background-color: transparent;
  color: $mine-shaft;

  svg {
    color: $cerulean !important;
  }
}

.UserFields {
  display: flex;
  flex-direction: column;
  gap: .25rem;

  .Name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
  }
}
