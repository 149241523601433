@import './breakpoints';
@import './colors';
@import './variables';
@import './typography';
@import './variables';

$postReplyMarginLeft: 3rem;

@mixin page {
  @include lato;
  color: $white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 4rem;
  width: 100%;
  min-height: 100vh;

  @include breakpoint(md) {
    display: grid;
    grid-template-columns:  1fr 34rem 1fr;
    padding: 0 1rem 0 0;
    gap: 1rem;
  }

  main {
    position: relative;
    padding: 1rem 0 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    min-height: 100vh;

    > * {
      width: 100%;
    }

    @include breakpoint(sm) {
      grid-column: 2;
      grid-row: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-top: 1rem;
      border-right: $border;
      border-left: $border;
      padding-bottom: 0;
      height: auto;
    }

    h1 {
      margin-top: 0;
      font-size: 1.5rem;
      padding-bottom: 1rem;

      @include breakpoint(sm) {
        font-size: 2rem;
      }
    }
  }
}

@mixin pageSingle {
  @include page;

  @include breakpoint(sm) {
    padding: 0;
  }
}

@mixin box-shadow {
  box-shadow: 0.2rem 0.2rem 0.5rem rgba($dove-gray, .25);
}

@mixin text-shadow {
  text-shadow: 0.2rem 0.2rem 0.5rem rgba($dove-gray, .25);
}

@mixin svg-shadow {
  svg {
    filter: drop-shadow(0.2rem 0.2rem 0.5rem rgb(0 0 0 / 0.4));
  }
}

@mixin UserField {
  grid-column: 1 / 3;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;

  @include breakpoint(sm) {
    max-width: 40rem;
  }
}

@mixin adminPage {
  @include page;

  @include breakpoint(sm) {
    display: grid;
    grid-template-columns:  14rem 1fr;
  }

  header {
    @include breakpoint(sm) {
      nav {
        margin: 1rem 0 0 2rem;
      }
    }
  }

  main {
    padding-top: 4rem;

    @include breakpoint(sm) {
      padding-top: 0;
    }

    h1 {
      padding-bottom: 0;
    }
  }
}

@mixin UserFieldEdit {
  @include UserField;
  display: block;
}

@mixin visuallyHidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin button {
  @include lato;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .25rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  background-color: $pizazz;
  padding: .5rem 1.5rem;
  border-radius: .25rem;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    color: $black;
    outline: none;
    background-color: rgba($pizazz, .8);
  }
}

@mixin like {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  gap: .5rem;
  color: $white;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@mixin simpleMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 100%;

  @include breakpoint(sm) {
    height: 25vh;
  }

  svg {
    width: 8rem;
    height: 8rem;
  }
}


@mixin imagePreviews() {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-column: 2;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 7rem;
  gap: .25rem;
  width: 100%;
}

@mixin imagePreview {
  .ImagePreview1 {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 2;
    width: 100%;
    min-height: 10rem;
    
    > * {
      height: 100%;
    }
  }

  .ImagePreview2 {
    @include imagePreviews;

    @include breakpoint(sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 12rem;
    }

  }

  .ImagePreview4 {
    @include imagePreviews;
    grid-template-rows: repeat(2, 7rem);

    @include breakpoint(sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 12rem);
    }

  }

  .ImagePreview6 {
    @include imagePreviews;
    grid-template-rows: repeat(3, 7rem);

    @include breakpoint(sm) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 12rem);
    }
  }

  .ImagePreview8 {
    @include imagePreviews;
    grid-template-rows: repeat(4, 7rem);

    @include breakpoint(sm) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 12rem);
    }
  }

  .ImagePreview10 {
    @include imagePreviews;
    grid-template-rows: repeat(5, 12rem);

    @include breakpoint(sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 12rem);
    }
  }

  .ImagePreview3 {
    @include imagePreviews;
    grid-template-rows: repeat(2, 7rem);

    > *:last-child {
      grid-column: 1 / 3;
    }

    @include breakpoint(sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 12rem);
    }
  }

  .ImagePreview5 {
    @include imagePreviews;
    grid-template-rows: repeat(3, 12rem);

    > *:last-child {
      grid-column: 1 / 3;
    }

    @include breakpoint(sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 12rem);
    }
  }

  .ImagePreview7 {
    @include imagePreviews;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 12rem);

    > *:last-child {
      grid-column: 1 / 3;
    }

    @include breakpoint(sm) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 12rem);

      > *:last-child {
        grid-column: 1 / 3;
      }
    }
  }

  .ImagePreview9 {
    @include imagePreviews;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 12rem);

    > *:last-child {
      grid-column: 1 / 3;
    }

    @include breakpoint(sm) {
      grid-template-rows: repeat(3, 12rem);
    }
  }
}

@mixin userListItem {
  display: grid;
  position: relative;
  grid-template-columns: 1.5rem 1.5rem 1fr 1.5rem 1fr 1fr 1.5rem;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
  text-decoration: none;
  width: 100%;

  @include breakpoint(sm) {
    grid-template-columns: 1.5rem 1.5rem 6rem 1.5rem 7rem 1fr 1fr 1.5rem;
  }

  @include breakpoint(md) {
    grid-template-columns: 1.5rem 1.5rem 6rem 1.5rem 10rem 1fr 1fr 1.5rem;
  }

  > * {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@mixin navText {
  display: block;

  @include breakpoint(md) {
    display: block;
  }
}

@mixin post {
  @include lato;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  color: $white;
  overflow: auto;
  padding: 1rem 0;
  width: 100%;

  a {
    border: 1px solid transparent;
  
    &:focus {
      border-color: $pizazz;
    }
  }

  .LinkPost {
    width: 100%;
    color: $white;
    cursor: pointer;

    &:focus {
      border-color: none;
    }
  }
}

@mixin postReplyLeftPad {
  margin-left: $postReplyMarginLeft;
}
