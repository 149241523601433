@import '../../../base/breakpoints';
@import '../../../base/colors';
@import '../../../base/typography';


.NavLogo {
  display: none;
  position: relative;
  color: $pizazz;

  @include breakpoint(sm) {
    @include nuttify;
    display: flex;
    position: relative;
    display: block;
    color: $pizazz;
    font-size: 2rem;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    padding: 0 0.5rem 0 0.25rem;
  }

  @include breakpoint(md) {
    padding: 0.5rem !important;
    display: flex;
  }

  img {
    width: 32px;
    margin-right: .25rem;
  }

  .Beta {
    text-transform: uppercase;
    font-size: 0.6rem;
    position: absolute;
    right: -0.1rem;
    bottom: -0.5rem;
    color: $white;
    font-feature-settings: normal;
    font-style: normal;
    font-weight: 600;
  }
}

