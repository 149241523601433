@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/variables';

@mixin button {
  color: $white;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  gap: .25rem;

  &:hover,
  &:active,
  &:focus {
    outline: none;
    text-decoration: underline;
  }

  > svg {
    width: 1rem;
    height: 1rem;
  }
}

.MoreButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 10;
  position: absolute;
  right: 1rem;
}

.MoreButtonsVertical {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 10;
  padding-bottom: .4rem;
}

.Buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  padding: .25rem;
  background: rgba($white, .95);
  box-shadow: 2px 2px 2px $mine-shaft;
  z-index: 20
}

.EditButton,
.EditMore,
.CancelEditButton,
.DeleteButton,
.CloseButton {
  @include button;
}

.EditMore {
  color: $white;
  position: absolute;
  right: 1rem;
  cursor: pointer;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.CloseButton {
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.EditMoreVertical {
  position: relative;
  color: $white;
  cursor: pointer;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.MoreButtonEdit {
  width: 100%;
  display: flex;
  flex-direction: column;

  .MoreButtonEditHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin: 0;
    }
  }
}

.ConfirmDeleteModal {
  width: 100vw;
  height: 100vh;
  background: rgba($cod-gray, .75);
  display: flex;
  align-items: center;
  justify-content: center;

  p { 
    margin-top: 0;
  }

  .ConfirmDelete {
    background: $white;
    padding: 3rem;
    color: $mine-shaft;
    border-radius: .5rem;
    text-align: center;

    @include breakpoint(sm) {
      max-width: 50%;
    }

    .Buttons {
      box-shadow: none;
    }

    > svg {
      width: 3rem;
      height: 3rem;
      color: $red;
    }

    .DeleteButton {
      @include button;
      background: $red;
      color: $white;
      padding: .5rem 1rem;
      border-radius: .25rem;
    }

    .CancelDeleteButton {
      @include button;
      background: $green;
      color: $white;
      padding: .5rem 1rem;
      border-radius: .25rem;
    }
  }
}

