@import './base/breakpoints';
@import './base/colors';
@import './base/globals';

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $cod-gray;
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
}
