@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';
@import '../../base/variables';

.Post {
  @include post;
  border: 1px solid transparent;
  border-bottom: $border;

  @include imagePreview;
  
  &:first-child {
    padding-top: 0;
  }

  .Text {
    cursor: pointer;
  }
}

.PostReply {
  @include post;
  padding-left: .5rem;
  border-left: .5rem solid $pizazz;
}

.Title {
  font-size: 1.2rem;
  margin-bottom: .5rem;
}

.Media {
  width: 100%;
  height: 20rem;
}

.PostFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;

  a {
    border: 1px solid transparent;
  
    &:focus,
    &:active {
      border-color: transparent;
    }
  }

  .more {
    color: $white;
    padding: .25rem .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
  
    &:hover,
    &:active,
    &:focus {
      opacity: .75;
    }
  }
}

.Photos {
  @include imagePreview;
  width: 100%;
}

.Videos {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  min-height: 15rem;
}

.Images {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.Replies {
   display: flex;
   flex-direction: column;
   gap: 0;
   width: 100%;
}

.PreHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}