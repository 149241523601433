@import '../../../base/breakpoints';
@import '../../../base/colors';
@import '../../../base/mixins';
@import '../../../base/typography';

.ProfileNav {
  @include breakpoint(md) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    justify-content: flex-start;
    column-gap: .25rem;
    text-align: left;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .5rem;
  }
}

.Text {
  @include navText;
}

.Modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .ConfirmLogout {
    background: $white;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    color: $mine-shaft;
    min-height: 15rem;

    h3 {
      margin: 0;
    }

    .Buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      a {
         text-decoration: none;
      }
    }

  }
}

