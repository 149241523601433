@import '../../base/colors';

.ProfileEditForm {
  grid-column: 1 / 5;
  margin-top: 2rem;
  padding: .5rem;
  background-color: transparent;

  > div {
    width: 100%;
  }

  .Warning {
    color: $red;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}