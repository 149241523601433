@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';

.More {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $alto;
  text-decoration: none;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.Name {
  grid-column: 2;
  grid-row: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  gap: .5rem;
}

.UserName {
  grid-column: 2;
  grid-row: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: .8rem;
  color: $gallery;
  gap: .5rem;

  @include breakpoint(sm) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  a {
    text-decoration: none;
  }

  button {
    font-size: .6rem;

    svg {
      width: .7rem;
      height: .7rem;
      margin-right: .25rem;
    }
  }
}

.ExtraUserFields {
  grid-column: 1 / 3;
  grid-row: 4;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}