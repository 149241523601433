@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';

.ImageEditable {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .background {
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;
    filter: blur(5px);
    position: absolute;
    z-index: 0;
    opacity: .5;
    top: 0;
    left: 0;
  }
  
  img {
    height: 100%;
    z-index: 10
  }
}


.ImageEditableNoFill {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    max-width: 100%;
  }
}