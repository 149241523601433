@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';

.SubscribeModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  text-decoration: none;
  margin-top: 1rem;
  gap: 1rem;
  text-align: center;

  br {
    display: none;

    @include breakpoint(sm) {
      display: inline;
    }
  }

  .Card {
    position: relative;
    padding: 2rem;
    background: $white;
    color: $mine-shaft;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem

    p {
      margin: 0 0 1rem 0;
    }
  }

  .Content {
    margin: 1rem 0;
  }

  .Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
  }
}

.Success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .Reloading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}