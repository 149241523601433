@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/mixins';

.CreateUsernameField {
  display: block;
  position: relative;

  > div {
    width: 100%;
  }
}

.Feedback {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: auto !important;
}
