@import '../../../base/breakpoints';
@import '../../../base/colors';
@import '../../../base/mixins';
@import '../../../base/typography';

@mixin badge {
  border-radius: 100%;
  border: 1px solid $white;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProfileNavButton {
  color: $white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(sm) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: .25rem;
    padding: .5rem 0;
    width: 11rem;
  }

  &:hover,
  &:active,
  &:focus {
    .Badge {
      outline: none;
      border-color: $pizazz;
      color: $white;
      background: $pizazz;
    }

    .Image {
      border-color: $pizazz;
    }
  }

  .Image {
    @include badge;
    background-size: cover;
    background-position: center center;
  }

  .Badge {
    @include badge;
  }
}


.Text {
  @include navText;
}