@import '../../base/breakpoints';
@import '../../base/colors';

.Squirrel {
  display: block;
  width: 90%;
  position: relative;

  @include breakpoint(sm) {
    grid-column: 1;
    grid-row: 2;
    width: 22rem;
  }

  img {
    max-width: 100%;
  }
}