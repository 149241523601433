@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';
@import '../../base/variables';

.PostReply {
  @include post;
  padding-left: .5rem;
  padding: 0;
  overflow: hidden;
  width: 100%;
}

.Line {
  position: absolute;
  top: 0;
  left: 2.85rem;
  border-right: 1px solid rgba($pizazz, .5);
}

.LinkPost {
  @include postReplyLeftPad;
}

.PostFooter {
  @include postReplyLeftPad;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  width: calc(100% - $postReplyMarginLeft);

  a {
    border: 1px solid transparent;
  
    &:focus,
    &:active {
      border-color: transparent;
    }
  }

  .more {
    color: $white;
    padding: .25rem .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
  
    &:hover,
    &:active,
    &:focus {
      opacity: .75;
    }
  }
}

.Photos,
.Videos {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  min-height: 15rem;
}

.Images {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
