@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';

.Like {
  @include like;

  &.vertical {
    flex-direction: column;
  }
}

.LikeVertical {
  @include like;
  color: $white;
  flex-direction: column;
}

.Vote {
  @include like;
}

.Selected {
  @include like;
  color: $pizazz;
}

