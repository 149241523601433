@import '../../../base/breakpoints';
@import '../../../base/colors';
@import '../../../base/mixins';
@import '../../../base/typography';

nav.Nav {
  @include lato;
  display: block;
  padding: .5rem 0 0 0;

  @include breakpoint(md) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0;
    width: 11rem;
  }

  ul {
    li {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      @include breakpoint(sm) {
        justify-content: flex-start;
        width: 100%;

        &:first-child {
          width: auto;
        }
      }

      &.bottomFixed {
        @include breakpoint(sm) {
          position: fixed;
          bottom: 0;
        }
      }

      ul {
        padding: 0;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid transparent;
        padding: .25rem;

        &:focus {
          color: $pizazz;
        }

        @include breakpoint(sm) {
          width: 100%;
        }

        svg {
          width: 2rem;
          height: 2rem;

          @include breakpoint(sm) {
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        .Text {
          @include navText;
          display: none;
        }

        .Badge {
          border-radius: 100%;
          border: 1px solid $white;
          width: 1.75rem;
          height: 1.75rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &[aria-current="page"] {
          color: $pizazz;
          font-weight: 700;

          @include breakpoint(sm) {
            background: rgba(255,255,255, 1);
            border-radius: 0.25rem;
          }
        }

        &.CreatePost {
          svg {
            color: $pizazz;
            width: 3rem;
            height: 3rem;

            @include breakpoint(sm) {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      }

      &:first-child a[aria-current="page"] {
        color: $pizazz;
        background: transparent;
        width: unset;
      }
    }
  }
}

.ProfileEditSmallScreens {
  display: block;

  @include breakpoint(sm) {
    display: none;
  }
}


.ProfileEditLargeScreens {
  display: none;

  @include breakpoint(sm) {
    display: block;
  }
}
