@import '../../../base/breakpoints';
@import '../../../base/colors';
@import '../../../base/typography';
@import '../../../base/mixins';

.SearchResult {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-content: flex-start;
  column-gap: .5rem;
  padding:  .5rem .75rem;
  border-bottom: 1px solid rgba($white, .25);

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: rgba($white, .1);;
  }

  > div:first-child {
    grid-row: 1 / 3;
    grid-column: 1;
  }

  .Name {
    grid-row: 1;
    grid-column: 2;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Username {
    grid-row: 2;
    grid-column: 2;
    font-size: .8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.NoResult {
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  align-items: center;
  justify-content: flex-start;
  padding:  1rem .75rem;
  gap: .5rem;
}
