@import '../../base/colors';

@mixin loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loading {
  @include loading;
}

.LoadingSquirrel {
  @include loading;
  animation: shake 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)  infinite;
  transform: rotate(0deg);
  backface-visibility: hidden;

  img {
    width: 100px;
    animation: zoom 2.5s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  95% {
    transform: rotate(-.5deg);
  }
}

@keyframes zoom {
  0% {
    transform: scale(1, 1);
}
  50% {
    transform: scale(1.25, 1.25);
  }
}