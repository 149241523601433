@import '../../base/breakpoints';
@import '../../base/colors';

.Feed {
  display: block;
  padding-top: 1rem;
}

.FeedList {
}
