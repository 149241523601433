@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';

.Home {
  @include breakpoint(sm) {
    max-width: 62rem;
    margin: 0 auto;
  }

  .Inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
  
    @include breakpoint(sm) {
      padding: 0 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 6rem 1fr;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
}

.About {
  @include breakpoint(sm) {
    max-width: 62rem;
    margin: 0 auto;
  }

  .Inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
}

.Blurb {
  display: block;
  padding: 1rem 2rem 0;
  text-align: center;

  @include breakpoint(sm) {
    max-width: 30rem;
  }
}

.Form {
  width: 100%;

  @include breakpoint(sm) {
    grid-column: 2;
    grid-row: 2;
  }
}

.Footer {
  display: block;
  clear: both;
  padding: 2rem;

  @include breakpoint(sm) {
    padding: 6rem 0;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    li a {
      color: $white;
      gap: .25rem;

      svg {
        color: $white;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .Copyright {
    font-size: .8rem;
    color: rgba($white, .8);
    text-align: center;
    padding: 1rem;
  }
}