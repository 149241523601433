@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/variables';

.MediaModal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  .Inner {
    background-color: rgba($cod-gray, .95);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100vw;
    height:  100vh;
  
    @include breakpoint(sm) {
      width: calc(75vw);
    }
  }
};


.Close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .5rem;
  z-index: 15;

  svg {
    color: $red;
    width: 1.5rem;
    height: 1.5rem;
  }
}