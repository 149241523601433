@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';
@import '../../base/variables';

.CreateAccountLogin {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;

  h2 {
    margin: 0;
  }

  p {
    margin: 0;
    padding: 0 1rem;
  }

  .Error {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    background: $white;
    color: $red;
    padding: 1rem;
    border-radius: .5rem;
    width: 80vw;

    @include breakpoint(sm) {
    width: 20rem;

    }
  }
}

.CreateAccountForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-align: center;
  padding: 1rem;
  width: 95%;
  
  > * {
    width: 100%;
  }

  @include breakpoint(sm) {
    align-items: flex-start;
    width: 20rem;
  }

  input {
    background-color: $cod-gray !important;
  }

  button {
    width: 100%;

    &[disabled] {
      background-color: rgba(255, 255, 255, 0.5);
      color: $white;
      cursor: not-allowed;
    }
  }
}

.Switch {
  a {
    color: $white;
  }
}

.LoggingIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-align: center;
  padding: 1rem;

  .text {
    text-align: center;
  }
}

.ShowMeNot {
  display: none;
}

