@import '../../../base/breakpoints';
@import '../../../base/colors';
@import '../../../base/typography';
@import '../../../base/mixins';

.SearchResults {
  @include box-shadow;
  position: absolute;
  top: 3.7rem;
  left: 0;
  width: 100%;
  border: 1px solid rgba($white, .25);
  border-radius: .25rem;
  background-color: $cod-gray;

}
