@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/variables';

@mixin numbers {
  position: absolute;
  top: .25rem;
  right: .5rem;
  z-index: 20;
  font-size: .7rem;
  text-align: right;
  text-shadow: 1px 1px 2px $black;
  background: rgba($black, 0.25);
  border-radius: .5rem;
  padding: .2rem .3rem;
}

.Numbers {
  @include numbers;
}

.NumbersModal {
  @include numbers;
  top: 2rem;
  right: .75rem;

  @include breakpoint(sm) {
    top: .75rem;
    font-size: .8rem;
  }
}