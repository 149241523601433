@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';
@import '../../base/variables';

.Carousel {
 width: 100%;
 position: relative;
 min-height: 12rem;
}

@mixin prevNext {
  position: absolute;
  top: calc(50% - 1.5rem);
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  z-index: 30;

  .Circle {
    width: 2rem;
    height: 2rem;
    background-color: $cod-gray;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: $white;
    }
  }
}

.Back {
  @include prevNext;
  left: 0;

  svg {
    padding-left: .2rem;
    width: 1.2rem;
    height: 1.2rem;
  }
}

.Forward {
  @include prevNext;
  right: 0;

  svg {
    width: .9rem;
    height: .9rem;
  }
}

.Slider {
   position: relative;
}

.Slide {
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
}

.OpenModal {
  width: 50%;
  position: absolute;
  left: 25%;
  top: 0;
  height: 100%;
  cursor: pointer;
  z-index: 30;
}

// for CarouselEdit
.VideoContainer {
  position: relative;
  width: 100%;
  height: 100%;

  .RemoveVideo {
    @include svg-shadow;
    position: absolute;
    top: .5rem;
    left: .5rem;
    color: $cerulean;
    z-index: 130;

    &:focus,
    &:active {
      color: $crimson;
    }
  }
}