@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';

.EditPost {
  @include lato;
  color: $white;
  padding: 1rem 1rem 0;
  position: relative;

  @include breakpoint(sm) {
    padding: 0 1rem;
  }

  

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .InputContainer {
      @include imagePreview;
      display: grid;
      grid-template-columns: 4rem 1fr;
      gap: .5rem;

      > label {
        @include visuallyHidden;
      }

      .PostContentInput {
        @include lato;
        grid-column: 2;
        border: none;
        width: 100%;
        resize: none;
        min-height: 6rem;
        background: transparent;
        color: $white;
        font-size: 1.25rem;

        &:focus,
        &:active,
        &:hover {
          outline: none;
        }
  
        &::placeholder {
          font-size: 1.25rem;
        }

        > div {
          height: 100%;
        }
      }

      .PostInputFooter {
        grid-column: 2;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }

    .Media {
      width: 100%;
      grid-column: 1 / 3;
      @include breakpoint(sm) {
        grid-column: 2;
      }
    }

    .VideoPreview {
      grid-column: 1 / 3;

      @include breakpoint(sm) {
        grid-column: 2;
      }
    }

    .AddMedia {
      display: grid;
      grid-template-columns: 2rem 1fr 6.5rem;
      align-items: center;
      justify-content: center;
      gap: .5rem;
      width: 100%;

      .Images {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        img {
          max-width: 100%;
        }

      }

      .AddImage,
      .AddVideo {
        display: flex;
        align-items: center;
        justify-content: center;

        > label {
          padding: .5rem;
        }
      }

      .CantAddImage,
      .CantAddVideo {
        color: $dune;
        opacity: 1;
        cursor: not-allowed;
      }

    }

    .Buttons {
      padding-left: .5rem;
      display: flex;
      gap: 1rem;
      position: relative;
      top: auto;
      right: auto;
    }
  }
}

.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .CreatingPost {
    background: $white;
    padding: 2rem;
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: $cod-gray;

    .title {
      grid-column: 2;
    }

    .info {
      grid-column: 1 /3;
      grid-row: 2;
      text-align: center;
    }
  }
}
