@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/mixins';

.Search {
  position: relative;
  overflow: visible;
  width: 100%;

  > div {
    width: 100%
  }
}

.Icon {
  position: absolute;
  top: .75rem;
  right: .5rem;
  z-index: 20;
  color: $white;

  svg {
    width: 2rem;
    height: 2rem;
  }
}