@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';

.ProfileHeader {
  @include lato;
  background: $cod-gray;
  color: $white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  padding: 0 1rem;
}

.UserDetails {
  z-index: 10;
  margin-top: 4.5rem;
  display: grid;
  grid-template-columns: 5rem 1fr 3rem 2.5rem 2.5rem;
  grid-template-rows: 2.5rem 1.5rem 1.5rem 1fr;
  row-gap: .25rem;
  width: 100%;

  @include breakpoint(sm) {
    margin-top: 6rem;
    grid-template-columns: 8.5rem 1fr 3rem 2.5rem 2.5rem;
    grid-template-rows: 4rem 1.5rem 1.5rem 1fr;
  }
}

.edit {
  grid-column: 3 / 5;
  grid-row: 1;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: .5rem;

  @include breakpoint(sm) {
    padding-top: 1.5rem;
    grid-column: 4 / 6;
  }
  
  a {
    display: flex;
    color: #fff;
    background: $dune;
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 2px #333;

    @include breakpoint(sm) {
      width: 2rem;
      height: 2rem;
    }

    &:last-child {
      background: $pizazz;
    }

    svg {
      zoom: .8;

      @include breakpoint(sm) {
        zoom: 1;
      }
    }
  }
}

.MessageButton {
  grid-column: 4;
  grid-row: 2;
  cursor: pointer;
};

.GetVerified {
  grid-row: 4;
  grid-column: 1 / 5;

  @include breakpoint(sm) {
    grid-column: 2 / 6;
    
    > div {
      justify-content: flex-end;
    }
  }
}