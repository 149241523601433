@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/variables';

@mixin image {
  background: $silver;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin profileImage {
  @include image;
  border-radius: 100%;
}

.ProfileImage {
  @include profileImage;
  width: 5rem;
  height: 5rem;
  border: .25rem $cod-gray solid;
  grid-column: 1;

  @include breakpoint(sm) {
    width: 8rem;
    height: 8rem;
  }

  label {
    font-size: .8rem;
  }
}

.ProfileImageMinimal {
  @include profileImage;
  width: 4rem;
  height: 4rem;
  grid-column: 1;
}

.ProfileImageMini{
  @include profileImage;
  width: 3rem;
  height: 3rem;
  grid-column: 1;
}

.ProfileImageComment {
  @include profileImage;
  width: 2rem;
  height: 2rem;
}

.HeaderImage {
  @include image;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 7rem;

  @include breakpoint(sm) {
    height: 10rem;
  }  
}

.FullscreenContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 90vw;

    @include breakpoint(sm) {
      max-width: 75vw;
    }
  }
}

.FullScreen {
  width: 100%;
  cursor: pointer;
}

.edit {
  padding: 0.5rem;
}
