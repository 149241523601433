@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/mixins';

.Sidebar {
  display: none;
  position: relative;

  @include breakpoint(sm) {
    grid-column: 3;
    width: 100%;
    padding: 1rem 0 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
  }

  aside {
    width: 100%;
    padding: 1rem 0 3rem;
    display: flex;
    flex-direction: column;
    
    @include breakpoint(sm) {
      max-width: 12rem;
    }
  
    @include breakpoint(lg) {
      max-width: 16rem;
    }
  }

}
