@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';
@import '../../base/variables';


.Container {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  padding: 0 .5rem 0 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.Text {
  grid-column: 1;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 100%;

  a {
    color: $pizazz;
  }
}


.Toggle {
  grid-column: 2;
  font-size: .8rem;
  text-align: right;
}