@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/mixins';

.RecommendedFollows {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1rem 3rem 1rem;
  background-color: rgba($white, .05);
  border-bottom: $border;

  h2 {
    margin-top: 0;
  }

  .Row {
    display: grid;
    grid-template-columns: calc(100% - 8.5rem) 8rem;
    gap: 0.5rem;

    > div,
    > a {
      padding: 0;
    }

    @include breakpoint(sm) {
      grid-template-columns: 1fr 10rem;
    }

    .FollowButtonContainer {
      grid-column: 2;
      grid-row: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
