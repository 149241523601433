@import '../../../base/breakpoints';
@import '../../../base/colors';
@import '../../../base/mixins';
@import '../../../base/typography';

.ShareEmail {
  display: flex;
  flex-direction: column;
  min-width: 12.5rem;

  .EmailInput {
    padding: .5rem;

    .InputContainer {
      display: grid;
      align-items: flex-start;
      justify-content: space-between;
      grid-template-columns: 1fr 4rem;
      gap: .5rem;

      .info {
        grid-row: 2;
        grid-column: 1 / 3;
        font-size: .8rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .button {
        grid-row: 1;
        grid-column: 2;
      }
    }
  }
}
