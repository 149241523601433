@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';

.FollowButton {
  position: relative;
  grid-row: 3;
  grid-column: 4 / 6;
  display: flex;
  gap: .5rem;
}

.UnFollowButton {
  position: relative;
  grid-row: 3;
  grid-column: 3 / 6;
  display: flex;
  gap: .5rem;
}

.AlertInner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $pizazz;
  }

  svg {
    color: $pizazz;
  }
} 