@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';

.FeedPage {
  @include page;

  main {
    padding-top: 0;

    @include breakpoint(sm) {
      padding-top: 1rem;
    }
  }
}
