@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/mixins';

.ButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.Button {
  @include button;
}

.ButtonPrimary {
  @include button;
  background-color: $pizazz;

  
}

.ButtonSecondary{
  @include button;
  background-color: $pizazz;
}

.ButtonCancel {
  @include button;
  background-color: $tundora;
}

.NotAllowed {
  cursor: not-allowed;
  background: $gray;
}

