@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';


.DateTime {
  @include lato;
  color: rgba($white, .8);
  font-size: .8rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
