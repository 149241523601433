@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/mixins';

.UserFieldOpen {
  @include UserField;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.UserFieldEdit {
  @include UserFieldEdit;
}

.UserFieldEditInvalid {
  @include UserFieldEdit;
  
  * {
    color: $red !important;
  }
}

.InputWrapper {
  padding: .5rem;
  position: relative;
  width: 100%;
  overflow: auto;

  > div {
    width: 100%;
    color: $white;
  }

  input {
    min-height: 1.5rem;
  }

  .Loading {
    position: absolute;
    top: .5rem;
    right: .5rem;
  }
}
