@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/variables';
@import '../../base/typography';

.Header {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 0 1rem 0;
  z-index: 1000;
  background: $cod-gray;
  border-top: $border;

  @include breakpoint(sm) {
    display: none;
  }

  @include breakpoint(md) {
    grid-column: 1;
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100vh;
    border-top: none;
  }
}