@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/mixins';

.ProfilePage {
  @include page;

  main {
    padding-top: 0;
  }
}
