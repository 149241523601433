@mixin breakpoint($class) {
  @if $class == xs {
    @media (min-width: 375px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px){ @content; }
  }
  
  @else if $class == md {
    @media (min-width: 1000px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == xl {
    @media (min-width: 1500px) { @content; }
  }
  
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
  }
}
