@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';
@import '../../base/mixins';

.HelpWidget {
  padding: 1rem;

  @include breakpoint(sm) {
    padding: 1rem 0;
  }
  
  h2 {
    font-size: 1rem;
  }

  .Current {
    color: $pizazz;
    font-weight: 700;
  }

  .All {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;

      a[aria-current="page"] {
        color: $pizazz;
      }
    }
  }
}
