@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';

@mixin postActions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
  position: relative;
  width: 100%;
}

.PostActions {
  @include postActions;
}

.PostActionsVertical {
  @include postActions;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.PostActionsWrapper {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
}