@import '../../base/breakpoints';
@import '../../base/colors';
@import '../../base/typography';

.Logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 11.5rem;

  @include breakpoint(sm) {
    margin-left: 5rem;
  }
  
}

.brand {
  @include nuttify;
  font-size: 4rem;
  color: $pizazz;
  // text-shadow: 0px 0px 10px #ffffff70;
  margin-bottom: -1rem;
}

.strapline {
  @include lato;
  color: white;
  font-size: .8rem;
  margin-left: -2rem;
  letter-spacing: -.7px;
  text-transform: uppercase;
}

.squirt {
  position: absolute;
  top: 0.5rem;
  left: 7.1rem;
  transform: rotate(-138deg) scale(1);
}

.winChromeSquirt {
  position: absolute;
  top: 0.4rem;
  left: 6.9rem;
  transform: rotate(-80deg) scale(1);
}

.NoDot {
  background: $cod-gray;
  position: absolute;
  top: .85rem;
  left: 7.25rem;
  width: .75rem;
  height: .75rem;
  border-radius: 100%;
  z-index: 0;
}

.Beta {
  text-transform: uppercase;
  font-size: 0.6rem;
  position: absolute;
  right: -0.1rem;
  bottom: -0.5rem;
  color: $white;
  font-feature-settings: normal;
  font-style: normal;
  font-weight: 600;
}

.LogoSmall {
  margin-left: 1rem;
  position: relative;
  padding-top: .5rem;
  width: 5.25rem;

  .brand {
    font-size: 1.5rem;
  }
  
  .strapline {
    display: none;
  }

  .NoDot {
    right: auto;
    left: 2.75rem;
    top: 0.9rem;
    width: 0.25rem;
    height: 0.25rem;
  }

  .Beta {
    font-size: 0.4rem;
    right: 0.2rem;
    bottom: 0;
  }
}

